import React, { useState, useEffect } from 'react';

const EmailCapture = () => {
    const [iframeWidth, setIframeWidth] = useState('360px');  // default width

    useEffect(() => {
        // Adjust iframe width based on window size
        if (window.innerWidth > 420) {
            setIframeWidth('400px');
        } else if (window.innerWidth <= 360) {
            setIframeWidth('320px');
        } else {
            setIframeWidth('360px');
        }

        // Dynamically load the script for the form
        const script = document.createElement('script');
        script.src = "https://forms-widget.getgist.com/";
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            // Clean up the script when the component unmounts
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="gist-embed-form" data-form-type="embedded" data-form-id="62255849" data-workspace-id="4dvgn76o" version="3"></div>
    );
};

export default EmailCapture;
