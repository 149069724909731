import phone from 'common/assets/image/workflow-2.svg';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import React from 'react';
import 'react-phone-number-input/style.css';
import Section, { AppPreview, Content, Grid } from './appDownload.style';
import EmailCapture from 'common/components/EmailCapture';

const AppDownload = () => {

  return (
    <Section id="early_access">
      <Container width="1300px">
        <Grid>
          <Content>
            <Heading content="🚀 Join the Future of Compliance with MamboAI" />
            <Text content="Experience the power of AI-driven marketing compliance ahead of the crowd. Sign up below and be a part of our exclusive early access program. ✨" />
            <div className="email-form">
              <EmailCapture />
            </div>
            <Text
                className="fyi"
                content="⚠️ Mambo AI is a compliance supplement, not a replacement. Final approvals from your compliance team are still necessary."
            />
          </Content>
          <AppPreview>
            <Image src={phone} alt="example workflow" />
          </AppPreview>
        </Grid>
      </Container>
    </Section>
  );
};

export default AppDownload;
